.unauth-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  background: #0f0c29; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .bg {
    svg {
      width: 65%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .content-wrapper {
    margin-top: 150px;
  }
  .content-sidebar {
    position: relative;
    height: 100%;
    padding: 3rem;
    background-color: azure;
    .testimonial {
      display: flex;
      align-items: flex-end;
      padding: 0 1.75rem;
      margin: 0 auto;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .unauth-wrapper .bg svg {
    display: none;
  }
}
