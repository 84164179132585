//
// menu.scss
//

.nav-second-level,
.nav-thrid-level {
  li {
    a {
      position: relative;
      display: block;
      padding: 8px 20px;
      color: $menu-item;
      transition: all 0.4s;

      &:focus,
      &:hover {
        color: $menu-item-hover;
      }
    }

    > a.active {
      color: $blue !important;
    }
  }
}

// Wrapper
#wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

//Content Page
.content-page {
  min-height: 80vh;
  padding: 72px 12.5px 5px 12.5px;
  //margin-top: $topbar-height;
  margin-left: $leftbar-width;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    margin-top: $topbar-height-mobile;
  }
}

// Sidemenu
.left-side-menu {
  position: fixed;
  top: $topbar-height;
  bottom: 0;
  z-index: 99;
  width: $leftbar-width;
  // padding: 30px 0;
  padding: 0 0 30px 0;
  background: $bg-leftbar-light;
  box-shadow: $box-shadow-sm;
  transition-duration: 0.2s;

  .sidebar-content {
    height: 100%;
    padding-bottom: 30px;
  }
}

// Sidebar
#sidebar-menu {
  padding-bottom: 30px;

  > ul {
    > li {
      &.mm-active {
        > a {
          color: $menu-item-active;
          background-color: $menu-item-bg-hover;
          border-left: 3px solid $menu-item-active;

          svg {
            color: $menu-item-active;
            fill: rgba($menu-item-active, 0.1);
          }
        }
      }

      > a {
        position: relative;
        display: block;
        padding: 12px 30px;
        font-size: 1rem;
        font-weight: 400;
        color: $menu-item;
        border-left: 3px solid transparent;
        transition: all 0.4s;

        svg {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin: 0 10px 0 3px;
          color: $menu-item;
          fill: rgba($menu-item, 0.12);
        }

        &:hover,
        &:focus,
        &:active {
          color: $menu-item-hover;
          text-decoration: none;
          background-color: $menu-item-bg-hover;

          svg {
            color: $menu-item-active;
            fill: rgba($menu-item-active, 0.1);
          }
        }

        > span {
          vertical-align: middle;
        }

        i {
          display: inline-block;
          margin: -1px 4px 0 0;
          line-height: 1rem;
          text-align: center;
          vertical-align: middle;
        }

        &.active {
          color: $menu-item-active;
          background-color: $menu-item-bg-hover;
          border-left: 3px solid $menu-item-active;

          svg {
            color: $menu-item-active;
            fill: rgba($menu-item-active, 0.1);
          }
        }
      }

      > ul {
        padding-left: 43px;

        ul {
          padding-left: 20px;
        }
      }
    }
  }

  .menu-arrow {
    position: absolute;
    top: 15px;
    right: 30px;
    display: inline-block;
    font-family: 'unicons';
    font-size: 0.9rem;
    line-height: 1.3rem;
    transition: transform 0.15s;
    transform: translate(0, 0);
    text-rendering: auto;

    &::before {
      content: '\e82f';
    }
  }

  .badge {
    margin-top: 3px;
  }

  .nav-second-level {
    > li {
      .menu-arrow {
        top: 8px;
      }
    }
  }

  li.mm-active {
    > a {
      > span.menu-arrow {
        transform: rotate(90deg);
      }
    }
  }

  .menu-title {
    padding: 15px 30px 10px 30px;
    font-size: 0.6875rem;
    color: $menu-item;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    pointer-events: none;
    cursor: default;
  }
}

// user profile
.user-profile {
  .avatar-xs {
    display: none;
  }

  .pro-user-name {
    font-weight: 700;
    color: $menu-item;
  }

  .pro-user-desc {
    font-size: 0.6rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .dropdown-toggle {
    svg {
      width: 18px;
      height: 18px;
      color: $menu-item;
      background-color: rgba($menu-item, 0.1);
      border-radius: 3px;
    }
  }
}

// small menu bar
.left-side-menu-condensed {
  .logo {
    width: $leftbar-width-collapsed !important;
    text-align: center;

    span {
      &.logo-lg {
        display: none;
      }

      &.logo-sm {
        display: block !important;
      }
    }
  }

  // Side menu
  .left-side-menu {
    position: absolute;
    z-index: 5;
    width: $leftbar-width-collapsed !important;

    .slimScrollDiv,
    .slimscroll-menu {
      height: auto !important;
      overflow: inherit !important;
    }

    .slimScrollBar {
      visibility: hidden;
    }

    .sidebar-content {
      height: auto;
    }

    // Sidebar Menu
    // side-menu
    #sidebar-menu {
      .menu-title,
      .menu-arrow,
      .label,
      .badge {
        display: none !important;
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          > a {
            min-height: 54px;
            padding: 15px;
            margin: 0;
            transition: none;

            &:hover,
            &:active,
            &:focus {
              color: $menu-item-hover;
            }

            svg {
              width: 26px;
            }

            span {
              display: none;
              padding-left: 25px;
              vertical-align: baseline;
            }

            &.active {
              background-color: $menu-item-bg-hover;
            }
          }

          &:hover {
            > a {
              position: relative;
              width: calc(190px + #{$leftbar-width-collapsed});
              color: $menu-item-active !important;
              background-color: $menu-item-bg-hover;
              transition: none;

              span {
                display: inline;
              }
            }

            a.open,
            a.mm-active {
              ::after {
                display: none;
              }
            }

            > ul {
              position: absolute;
              left: $leftbar-width-collapsed;
              display: block;
              width: 190px;
              height: auto !important;
              box-shadow: $box-shadow-sm;

              ul {
                box-shadow: $box-shadow-sm;
              }

              a {
                position: relative;
                z-index: 6;
                width: 190px;
                padding: 8px 20px;
                box-shadow: none;

                &:hover {
                  color: $menu-item-hover;
                }
              }
            }
          }
        }

        ul {
          z-index: 9999;
          display: none;
          padding: 5px 0;
          background-color: $bg-leftbar-light;

          li {
            &:hover {
              > ul {
                position: absolute;
                left: 190px;
                display: block;
                width: 190px;
                height: auto !important;
                margin-top: -36px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                top: 12px;
                right: 20px;
                transform: rotate(270deg);
              }
            }
          }

          li.active {
            a {
              color: $menu-item-hover-color-dark;
            }
          }
        }
      }
    }
  }

  .user-profile {
    //padding: 15px;

    .avatar-sm {
      display: none;
    }

    .avatar-xs {
      display: block;
    }

    // .pro-user-name,
    // .pro-user-desc {
    //   display: none;
    // }

    .profile-dropdown-menu {
      .dropdown-toggle {
        svg {
          background-color: transparent;
        }
      }
    }
  }

  // Content Page
  .content-page {
    margin-left: $leftbar-width-collapsed !important;
  }

  //Footer
  .footer {
    left: 0 !important;
  }

  //User box
  .user-box {
    display: none;
  }
}

// Body min-height set

@media (min-width: 769px) {
  body.left-side-menu-condensed {
    min-height: 1200px;
  }
}

@media (max-width: 1024px) {
  .left-side-menu {
    top: $topbar-height;
    padding: 12px 0;
  }

  .left-side-menu-condensed {
    .left-side-menu {
      padding-top: 12px;
    }
  }
}

@media (max-width: 1025px) {
  body:not(.left-side-menu-condensed) {
    .left-side-menu {
      overflow-y: auto;
    }
  }
}

@include media-breakpoint-down(sm) {
  body {
    overflow-x: hidden;
  }

  .left-side-menu {
    top: $topbar-height;
    z-index: 10 !important;
    display: none;
  }

  .sidebar-enable {
    .left-side-menu {
      display: block;
    }
  }

  .content-page,
  .left-side-menu-condensed .content-page {
    margin-left: 0 !important;
  }

  // .pro-user-name {
  //   display: none;
  // }

  .logo-box {
    display: none;
  }
}

/* =============
  Menu - Dark
============= */

// Leftbar-dark
.left-side-menu-dark {
  .metismenu {
    li {
      a[aria-expanded='true'] {
        color: $menu-item-active-color-dark !important;
      }
    }
  }

  .left-side-menu {
    background-color: $bg-leftbar-dark;
    box-shadow: none;

    #sidebar-menu {
      > ul {
        > li {
          &.mm-active {
            > a {
              color: $menu-item-active-color-dark;
              background-color: lighten($bg-leftbar-dark, 4%);

              svg {
                color: $menu-item-active-color-dark;
                fill: rgba($white, 0.12);
              }
            }
          }

          > a {
            color: $menu-item-color-dark;

            svg {
              color: $menu-item-color-dark;
              fill: rgba($white, 0.12);
            }

            &:hover,
            &:focus,
            &:active {
              color: $menu-item-hover-color-dark;
              background-color: lighten($bg-leftbar-dark, 4%);
            }

            &.active {
              color: $blue !important;
              background-color: lighten($bg-leftbar-dark, 4%);

              svg {
                color: $blue !important;
                fill: rgba($white, 0.12);
              }
            }
          }
        }
      }

      .menu-title {
        color: $gray-500;
      }
    }
  }

  .nav-second-level,
  .nav-thrid-level {
    li {
      a {
        color: $menu-item-color-dark;

        &:focus,
        &:hover {
          color: $menu-item-hover-color-dark;
          background-color: transparent;
        }
      }

      &.mm-active {
        > a {
          color: $menu-item-active-color-dark;
        }
      }
    }
  }

  &.left-side-menu-condensed {
    .left-side-menu {
      #sidebar-menu {
        > ul {
          > li {
            &:hover > a {
              color: $menu-item-active-color-dark !important;
              background-color: lighten($bg-leftbar-dark, 4%);
            }
          }
        }
      }
    }

    .nav-second-level,
    .nav-thrid-level {
      li {
        &.mm-active {
          > a {
            color: $menu-item-active;
          }
        }
      }
    }
  }

  .user-profile {
    .pro-user-name,
    .pro-user-desc {
      color: $gray-500;
    }

    .profile-dropdown-menu {
      .dropdown-toggle {
        svg {
          color: $white;
          background-color: lighten($bg-leftbar-dark, 4%);
        }
      }
    }
  }
}
