//
// layouts.scss
//

// scrollable-layout

@include media-breakpoint-up(xl) {
  .scrollable-layout {
    padding-bottom: 0;
    #wrapper {
      display: flex;
    }

    .left-side-menu {
      position: relative;
      min-width: 250px;
      min-height: 100vh;
    }

    .navbar-custom {
      position: absolute;
    }

    .content-page {
      width: 100%;
      padding-bottom: 60px;
      margin-left: 0;
    }

    &.left-side-menu-condensed {
      .left-side-menu {
        min-width: 70px;
      }
      .content-page {
        margin-left: 0 !important;
      }
    }
  }
}

// boxed-layout

.boxed-layout {
  background: darken($body-bg, 2%);

  #wrapper {
    max-width: $boxed-layout-width;
    margin: 0 auto;
    background: $body-bg;
    box-shadow: $box-shadow;
  }

  .navbar-custom {
    max-width: $boxed-layout-width;
    margin: 0 auto;
  }

  .footer {
    max-width: calc(#{$boxed-layout-width} - #{$leftbar-width});
    margin: 0 auto;
    background: $body-bg;
  }

  &.left-side-menu-condensed {
    min-height: auto;
    .footer {
      max-width: $boxed-layout-width;
    }
  }
}
.dropdown-item {
  display: flex;
  align-items: center;
  &.active {
    color: $primary;
  }
}
.profile-dropdown i.feather {
  height: 20px;
}