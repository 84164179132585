















































.bank {
  padding: 2px;
  border-radius: 7px;
}
