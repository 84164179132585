@import './pages/un-auth.scss';

.card {
  .card-header {
    background-color: white !important;
    font-size: 1.04rem;
  }
}
.table {
  min-height: 250px;
}
.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: $light;
}

.b-icon.bi {
  color: $dark;
}

.media-aside {
  margin-right: 0.3rem;
}
